.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  text-align: left;
  padding: 0;
  background-color: black;
  margin-top: 0px;
}

h1 {
  color: white;
  font-size: 56px;
  margin-top: 10px;
  font-weight: 400;
}

.centerText {
  display: block;
  text-align: center;
}

.title {
  font-size: 4.5rem;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  text-align: center;
}

.subtitle {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  max-width: 500px;
  margin-bottom: 30px;
  text-align: center;
  margin: 17px 40px 30px 40px;
}

.button {
  background: #ffffff;
  color: black;
  border: none;
  padding: 12px 25px;
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 0.5rem;
  margin-bottom: 3.5rem;
}

.learnMore {
  background: black;
  color: #ffffff;
  border: none;
  padding: 17px 25px;

  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  position: relative;
  align-self: flex-start;
}

.learnMore::after {
  content: "";
  position: absolute;
  width: 65%;
  height: 0.5px;
  bottom: 14px;
  left: 15%;
  background-color: #ffffff;
  opacity: 0;
  transition: opacity 0.6s ease;
}

.learnMore:hover::after {
  opacity: 1;
}

.iconAdjust {
  vertical-align: middle;
  margin-bottom: 3px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 110px;
}

.preText {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  color: #ffffff;
  background-color: black;
  font-size: 16px;
  padding-top: 115px;
  margin-bottom: 0px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoReflection {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  color: #ffffff;
  font-size: 14px;
  margin-top: 60px;
  display: flex;
  justify-content: start;
  margin-left: 70px;
  font-weight: 400;
}

.carouselContainer {
  display: flex;
  gap: 0px;
  margin-bottom: 20px;
  overflow: hidden;
  width: max-content;
  animation: moveCards 600s linear infinite;
  cursor: pointer;
}

.carouselContainerReverse {
  display: flex;
  gap: 0px;
  margin-bottom: 20px;
  overflow: hidden;
  width: max-content;
  animation: moveCardsReverse 600s linear infinite;
  cursor: pointer;
}

.carouselWrapper {
  width: 100%;
  overflow-x: hidden;
}

.signInButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

@keyframes moveCards {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-33.33%));
  }
}

@keyframes moveCardsReverse {
  0% {
    transform: translateX(calc(-33.33%));
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 3rem;
    text-align: center;
  }

  .subtitle {
    font-size: 1.5rem;
    text-align: center;
  }

  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    font-family: "Microsoft Sans Serif", Arial, sans-serif;
    font-size: calc(10px + 2vmin);
    min-height: 100vh;
    text-align: center;
    padding: 1rem;
    background-color: black;
    width: 100%;
    box-sizing: border-box;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    background-color: bluc;
  }

  .logoReflection {
    font-size: 12px;
    margin-left: 0px;
    margin-right: 0px;
    color: #ffffff;
  }

  .subtitle {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .title {
    font-size: 3rem;
    max-width: 90%;
    text-align: center;
    margin: 0 auto;
  }

  .subtitle {
    font-size: 1.2rem;
    color: #ebe3e3;
    max-width: 80%;
    margin: 1rem auto;
  }

  .app {
    padding: 2rem;
  }

  .wrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 3rem;
    max-width: 800px;
    text-align: center;
  }

  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    font-family: "Roboto", sans-serif;
    font-size: calc(10px + 2vmin);
    min-height: 100vh;
    text-align: left;
    padding: 0rem;
    background-color: black;
  }

  .wrapper {
    min-height: 100vh;
  }
}
