body {
  margin: 0;
  background-color: black;
}

.app {
  display: flex;
  flex-direction: column;
  color: black;
  font-family: "Microsoft Sans Serif", Arial, sans-serif;

  width: 100%;
  background-color: black;
  margin-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.h1 {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  color: #ffffff;
  font-size: 24px;
  margin: 30px 0 40px 0;
  font-weight: 400;
  text-align: center;
}

.paragraph {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  color: #ffffff;
  font-size: 16px;
  margin: 0 0 50px 0;
  line-height: 1.6;
  text-align: left;
  font-weight: 400;
}

.centered {
  text-align: center;
}
