.container {
  width: 225px;
  height: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #171717;
  transition: transform 0.3s ease-in-out;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 15px;
}

.container h2 {
  color: #ddd4d4;
  font-size: 1.2rem;
  margin: 0;
}

.container ul {
  list-style-type: none;
  padding: 0;
}

.container li {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 10px;
  padding: 8px;
  background-color: #1a1a1a;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.container li:hover {
  background-color: #2a2a2a;
}

@media (max-width: 1024px) {
  .container {
    transform: translateX(-100%);
  }
}

.showSidebar {
  transform: translateX(0) !important;
}

.deleteAllButton {
  padding: 5px 10px;
  background-color: #2e2e2e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
}

.deleteAllButton:hover {
  background-color: #565656;
}
