.app {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  width: 100%;
  border-bottom: #171717 1px solid;
  height: 50px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1000;
}

.headerContainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 60px;
}

.h1 {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: 18px;
  color: #ffffff;
  margin: 0;
}

.h2 {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: 14px;
  color: #ffffff;
  margin-top: 15px;
}

.navLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  text-decoration: none;
  color: inherit;
  margin: 0 10px;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 767px) {
  .headerContainer1 {
    padding: 0 15px;
  }

  .h1 {
    font-size: 16px;
    margin-left: 10px;
  }

  .navLinks {
    position: absolute;
    top: 50px;
    right: 0;
    width: 200px;
    background-color: black;
    border: 1px solid white;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .h2 {
    font-size: 14px;
    margin-top: 12px;
    margin-right: 10px;
  }

  .link {
    margin: 10px 0;
  }

  .navLinks {
    display: none;
  }
}
