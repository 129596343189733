.carouselCard {
  height: 100px;
  width: 400px;
  background-color: #1a1a1a;
  margin: 0 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 100;
  padding-left: 20px;
}

.cardBody {
  padding-left: 20px;
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  font-size: 16px;
  font-weight: 100;
  color: white;
  text-align: left;
  padding-right: 35px;
}
