body {
  background-color: black;
  color: #ddd4d4;
  font-family: "Roboto", sans-serif;
}

.contentContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin-top: 50px;
  padding: 50px;
}

.loginContainer {
  position: absolute;
  right: 20px;
  top: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userInfo span {
  font-size: 14px;
  color: #ddd4d4;
}

.logoutButton {
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logoutButton:hover {
  background-color: #3a7bc8;
}

.mainText {
  margin-bottom: 25px;
  margin-top: 60px;
  font-size: 24px;
}

.form {
  border: 2px solid #ddd4d4;
  border-radius: 25px;
  outline: none;
  padding: 10px;
  width: 300px;
  font-size: 18px;
  background-color: black;
  color: #ddd4d4;
  transition: all 0.3s ease-in-out;
}

.form:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
}

.submitButton {
  border: 1px solid #ddd4d4;
  border-radius: 25px;
  height: 44px;
  margin-left: 10px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  background-color: black;
  color: #ddd4d4;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitButton:hover {
  background-color: #1a1a1a;
  border-color: #4a90e2;
}

.submitButton.active {
  border: 2px solid #4a90e2;
  background-color: #4a90e2;
  color: black;
}

.submitButton.active:hover {
  background-color: #3e82cf;
  border-color: #3e82cf;
}

.character {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  max-width: 600px;
  overflow-x: hidden;
}

.character-container {
  display: inline-block;
  max-width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.hoverable-character {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.hoverable-character:hover {
  transform: scale(1.05);
}

.signInButton {
  position: absolute;
  left: 20px;
  top: 20px;
  margin-left: 20px;
  margin-top: 20px;
}

.synthesizeButton {
  border: 1px solid #ddd4d4;
  border-radius: 25px;
  height: 44px;
  margin-left: 10px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  background-color: black;
  color: #ddd4d4;
  cursor: pointer;
  transition: all 0.3s ease;
}

.synthesizeButton:hover {
  background-color: #1a1a1a;
  border-color: #4a90e2;
}

.synthesizeButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
