.quoteWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 40px;
  padding: 40px 20px;
  background: black;
}

.quoteContent {
  max-width: min(60%, 800px);
  text-align: center;
}

.quoteText {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 0 20px 0;
  font-style: italic;
  position: relative;
  padding: 0 25px;
}

.quoteText::before,
.quoteText::after {
  content: '"';
  position: absolute;
  font-size: 24px;
  color: #888;
}

.quoteText::before {
  left: 0;
}

.quoteText::after {
  right: 0;
}

.quoteAuthor {
  font-family: "Microsoft Sans Serif", Arial, sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 200;
}
